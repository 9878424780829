.spotifyplay__div{
  height: 100%;
  width: 100%;
}

.spotify-heading {
    font-size: 40px; /* Increase the font size as needed */
    margin-left: 5vw;
  }
  
  .spotify-bar {
    display: flex; /* Use flexbox layout */
    gap: 50px; /* Add some spacing between the elements */
    animation: scrollAnimation 80s linear infinite; /* Add the scroll animation */
  }
  
  .spotify-bar > div {
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange the content in a column */
    align-items: center; /* Center the content horizontally */
    text-align: center; /* Center the text horizontally */
    flex-shrink: 0; /* Prevent the elements from shrinking */
    width: 400px; /* Adjust the width of each element */
  }
  
  .spotify-bar img {
    max-width: 400px; /* Make the album images fill the container width */
  }
  
  .spotify-bar p {
    color: #fff; /* Set the text color to white */
  }
  
  @keyframes scrollAnimation {
    0% {
      transform: translateX(0); /* Start from initial position */
    }
  
    100% {
      transform: translateX(calc(-100% + 20px)); /* Move to the left by the width of each element plus spacing */
    }
  }

  .section2__iframe__div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw; /* 50% of the viewport width */
    height: 60vh; /* 50% of the viewport height */
    margin-left: 15vw;
  }

  /* .spotify__iframe{
    width: 1000px;
    height: 400px;
  } */