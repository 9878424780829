.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  color: white;
  height: 60px; /* Set a consistent height */
}

.navbar__left {
  display: flex;
  align-items: center;
}

.navbar__logo {
  max-width: 100%;
  max-height: 70px;
  margin-right: 1rem;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
}


.navbar__right {
  display: flex;
}

.navbar__link {
  display: inline-block;
  margin-right: 3rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: #ffffff;
  position: relative;
}

.navbar__link:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.navbar__link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

/* For mobile screens */
@media (max-width: 768px) {
  .navbar__logo {
    max-height: 50px; /* Reduce the size */
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .navbar__logo {
    display: none; /* Hide the logo */
  }
}