.container {
  height: auto; /* Remove fixed height */
  overflow-x: hidden; /* Hide horizontal scrolling */
  overflow-y: scroll; /* Allow scrolling between sections */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}

section {
  height: 100vh; /* Height of each section */
}

.section-1 {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.section-2 {
  height: 150vh;
  background-color: #091222;
  position: relative; /* Add position relative to the section */
  overflow: hidden; /* Hide overflowing content within the section */
  display: flex; /* Use flexbox layout */
  flex-direction: column;
  align-items: flex-start; /* Center the content vertically */
  justify-content: flex-start; /* Center the content horizontally */
}

.section2__content {
  background-color: #091222; /* Set the background color */
  display: flex; /* Use flexbox layout */
  align-items: flex-start; /* Center the content vertically */
  justify-content: flex-start; /* Align the content to the start */
  overflow: hidden; /* Hide the scroll bar */
}

.section2__content h2 { 
  color: #fff; /* Set the heading text color to white */
}

.section-3 {
  height: 80vh;
  background-color: #091222;
  display: flex;
  align-items: flex-end; /* Align items vertically at the bottom */
  justify-content: center; /* Align items horizontally at the center */
  padding-bottom: 20px; /* Add some bottom padding for spacing */
}

.section3__content {
  text-align: center; /* Center the content horizontally */
}


/* Media Queries for Mobile Devices */

@media (max-width: 768px) {
  .section-1,
  .section-2,
  .section-3 {
    min-height: auto; /* Remove fixed heights for mobile devices */
  }

  .section2__iframe__div {
    margin-left: 0; /* Remove margin-left for mobile devices */
  }
}