.social-bar {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: transparent;
}

.social-icon {
  display: flex; /* Add flexbox layout to center the image */
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  margin-right: 25px;
  margin-left: 25px;
}

.social-icon:hover {
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;
}

/* Media Queries for Responsive Layout */

@media (max-width: 930px) {
  .social-bar {
    flex-wrap: wrap; /* Allow the icons to wrap to the next line */
    height: auto; /* Let the height adjust based on content */
    width: 90%;
    justify-content: center;
  }

  .social-icon {
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .social-bar {
    flex-wrap: wrap; /* Allow the icons to wrap to the next line */
    height: auto; /* Let the height adjust based on content */
    width: 90%;
    justify-content: center;
  }

  .social-icon {
    margin: 10px;
  }
}