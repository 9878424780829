.footer {
  background-color: #131314; /* Ash gray color */
  padding: 20px;
  color: #fff; /* White text color */
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content .copyright {
  margin: 0;
}
