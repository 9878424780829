.getintouch {
  text-align: center;
  background-color: #091222;
  padding: 20px 0;
  color: white;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.contact-item i {
  margin-right: 10px;
}

.contact-item p {
  margin: 0;
}

.getintouch h1 {
  font-size: 60px;
}

.getintouch h3 {
  font-size: 35px;
}

.getintouch p {
  font-size: 20px;
}